<template>
  <div class="ExamSetTaskRules">
    <ExamDetailsHeader
      ref="ExamDetailsHeader"
      :router-url="
        $route.name == 'ExamSetTaskRules'
          ? 'ExamCorrectTask'
          : 'unionExamCorrectTask'
      "
      :query="$route.query"
    ></ExamDetailsHeader>
    <div class="tab-box">
      <el-table :data="exammarkList" border style="width: 100%">
        <el-table-column
          prop="questionNum"
          label="题目名称"
          width="282"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.isOption == 1 ? "选做题" : "主观题"
            }}{{ scope.row.questionNum }}
          </template>
        </el-table-column>

        <el-table-column v-if="examQuery.type == 1" align="center">
          <template #header>
            <div style="margin-bottom: 12px">操作</div>
            <el-select
              v-model="ruleScore"
              style="width: 130px"
              placeholder="请选择"
              @change="setQuestionList"
            >
              <el-option
                v-for="item in scoreRulesList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <span v-if="ruleScore > 1" style="width: 130px; margin-left: 12px">
              误差分值:
              <el-input
                v-model="errorScore"
                style="width: 130px"
                placeholder="请输入内容"
                oninput='value = (value.match(/^[0-9]+(\.[0-9]{0,2})?/g) ?? [""])[0]'
                @blur="errorScore = $event.target.value"
                @change="setQuestionList"
              ></el-input>
            </span>
            <el-select
              v-model="scaleType"
              placeholder="请选择"
              style="width: 130px; margin-left: 12px"
              @change="setQuestionList"
            >
              <el-option
                v-for="item in decimalDigits"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="carryType"
              placeholder="请选择"
              style="width: 130px; margin-left: 12px"
              @change="setQuestionList"
            >
              <template v-for="item in digitsMethod">
                <el-option
                  v-if="getshow(item.value)"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </template>
            </el-select>
          </template>
          <template slot-scope="scope">
            <!-- <template v-if="getDis(scope.row)">
              {{ scope.row.bindQuestionNum }} 绑定批阅
            </template> -->
            <!-- <template v-else> -->
            <div class="tab-item">
              评分规则：
              <el-select
                v-model="scope.row.ruleScore"
                placeholder="请选择"
                :disabled="getDis(scope.row)"
              >
                <el-option
                  v-for="item in scoreRulesList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div v-if="scope.row.ruleScore != 1" class="tab-item">
              误差分值：
              <el-input
                v-model="scope.row.errorScore"
                placeholder="请输入内容"
                oninput='value = (value.match(/^[0-9]+(\.[0-9]{0,2})?/g) ?? [""])[0]'
                @blur="scope.row.errorScore = $event.target.value"
                @input="setChangeNum(scope.row)"
              ></el-input>
              <span class="score"
                >（满分：{{ scope.row.questionScore }}分）</span
              >
            </div>
            <div v-if="scope.row.ruleScore != 1" class="tab-item">
              平均分计算规则：小数位数：
              <el-select
                v-model="scope.row.scaleType"
                placeholder="请选择"
                :disabled="getDis(scope.row)"
                @change="checkScale(scope.row)"
              >
                <el-option
                  v-for="item in decimalDigits"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              进位方式：<el-select
                v-model="scope.row.carryType"
                :disabled="getDis(scope.row)"
                placeholder="请选择"
                ><template v-for="item in digitsMethod">
                  <el-option
                    v-if="getshow(item.value, scope.row)"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </template>
              </el-select>
            </div>
            <!-- </template> -->
          </template>
        </el-table-column>
        <template v-else>
          <el-table-column
            prop="questionScore"
            label="题目分数"
            width="282"
            align="center"
          >
          </el-table-column>
          <el-table-column align="center">
            <template #header>
              <div class="tab-item-title">约束给分点</div>
              <el-radio v-model="markStep" :label="1" @change="markStepChange">
                批量设置步长
              </el-radio>
              <el-input
                v-if="markStep === 1"
                v-model="stepVal"
                oninput="value=value.replace(/[^0-9.,]/g,'')"
                placeholder="请输入"
                class="set-input"
                @blur="stepVal = $event.target.value"
                @input="setlistStep()"
              ></el-input>
              <el-radio v-model="markStep" :label="2" @change="markStepChange">
                自定义给分点
              </el-radio>
            </template>
            <template slot-scope="scope">
              <template>
                <div v-if="scope.row.markType != 3" class="el-radio-box">
                  <el-radio
                    v-for="(v, i) in stepSize"
                    :key="i"
                    v-model="scope.row.markStep"
                    :label="v.value"
                    @change="markStepItemChange(scope.row)"
                    >{{ v.label }}</el-radio
                  >
                </div>
                <div>
                  <template v-if="scope.row.markType == 3">
                    已设置分项给分
                    <el-button type="text" @click="setScore(scope.row)"
                      >去设置</el-button
                    >
                  </template>
                  <el-input
                    v-if="scope.row.markType != 3"
                    v-model="scope.row.markScore"
                    class="mark-score"
                    placeholder="请输入内容"
                    oninput="value=value.replace(/[^0-9.,]/g,'')"
                    @blur="scope.row.markScore = $event.target.value"
                  ></el-input>
                </div>
                <div v-if="!getDis(scope.row) && scope.row.bindId">
                  <el-button type="text" @click="checkVal(scope.row)"
                    >适用于当前绑定试题</el-button
                  >
                </div>
                <div v-if="getDis(scope.row)">
                  {{ scope.row.bindQuestionNum }}为绑定批阅
                </div>
              </template>
            </template>
          </el-table-column>
          <template v-if="examQuery.examType == 1">
            <el-table-column
              prop="questionNum"
              label="最低阅卷时间"
              width="282"
              align="center"
            >
              <template #header>
                最低阅卷时间
                <el-switch v-model="minTime" active-color="#2474ED">
                </el-switch>
              </template>
              <template slot-scope="scope">
                <template v-if="getDis(scope.row)">
                  {{ scope.row.bindQuestionNum }} 绑定批阅
                </template>
                <template v-else>
                  <el-input
                    v-model="scope.row.markTime"
                    style="width: 60px"
                    oninput="value=value.replace(/[^\d]/g,'')"
                    placeholder=""
                    @blur="scope.row.markTime = $event.target.value"
                  ></el-input>
                  秒
                </template>
              </template>
            </el-table-column>
          </template>
        </template>
      </el-table>

      <el-button
        type="primary"
        :loading="btnLoading"
        class="submit-btn"
        @click="submitListCnt"
      >
        保 存
      </el-button>
    </div>
    <!-- 查看给分方式 -->
    <el-dialog
      title="查看给分方式"
      :visible.sync="scoreMethodDialogVisible"
      width="30%"
    >
      <span>查看给分方式</span>
    </el-dialog>
  </div>
</template>

<script>
import {
  exammarkTeacherList,
  exammarkRuleScore,
  schoolExamwork,
  exammarkClassList,
} from "@/core/api/exam/examCorrectTask";
import {
  decimalDigits,
  digitsMethod,
  scoreRulesList,
  scoreMethod,
  stepSize,
} from "@/core/util/constdata";
// import ExamDetailsHeader from "./component/ExamDetailsHeader";
import ExamDetailsHeader from "@/components/detailsHeader";
import { getObj } from "@/core/api/exam/exam";
import { removeStore, setStore } from "@/core/util/store";
export default {
  name: "ExamSetTaskRules",
  components: {
    ExamDetailsHeader,
  },
  data() {
    return {
      // 给分方式
      markType: "",
      // 约束给分点
      markStep: "",
      stepVal: "",
      scoreMethodDialogVisible: false,
      btnLoading: false,
      minTime: false,
      carryType: "",
      scaleType: "",
      ruleScore: "",
      errorScore: "",
      changeKey: new Date().getTime(),
      decimalDigits: decimalDigits,
      digitsMethod: digitsMethod,
      scoreRulesList: scoreRulesList,
      // scoreRulesList: scoreRulesList.concat({ value: 4, label: "部分双评" }),
      scoreMethod: scoreMethod,
      stepSize: stepSize,
      examQuery: {},
      examInformation: {},
      exammarkList: [],
      name: "",
      subjectName: "",
    };
  },
  created() {
    this.examQuery = this.$route.query;
    this.exammarkTeacherList();
    this.getObj();
  },
  methods: {
    // 分项给分
    setScore(item) {
      removeStore({
        name: "exammarkPointItem",
        type: 1,
      });
      removeStore({
        name: "question",
        type: 1,
      });
      // removeStore({
      //   name: "tId",
      //   type: 1,
      // });
      // console.log(this.examInformationPaper)
      setStore({
        name: "questionId",
        type: 1,
        content: item.sheetDetailId,
      });
      let data = { ...this.$route.query };
      // data.fromUrl = this.$route.name;

      this.$router.push({
        name: "ExamTaskRules",
        query: data,
      });
    },
    getDis(item) {
      let type = false;

      if (item.bindQuestionNum) {
        if (item.bindQuestionNum.indexOf(item.questionNum) != 0) {
          type = true;
        }
      }
      return type;
    },
    //获取考试基本信息
    getObj() {
      getObj(this.$route.query.examId).then((res) => {
        this.name = res.data.data.name;
        this.examInformation = res.data.data;
        res.data.data.paperList.map((item) => {
          if (item.paperId == this.$route.query.paperId) {
            this.subjectName = item.subjectName;
          }
        });
      });
    },
    setChangeNum(item) {
      // console.log(this.studentIdNum);
      if (item.errorScore > item.questionScore) {
        item.errorScore = item.errorScore.substr(0, item.errorScore.length - 1);
      }
    },
    exammarkClassList() {
      let data = {
        examPaperId: this.examQuery.paperId,
      };
      this.listLoading = true;
      exammarkClassList(data)
        .then((res) => {
          // console.log(res);
          if (res.data.data.length == 0) {
            this.exammarkList = [];
          }
          // // 添加两个显示任务量的弹窗
          // res.data.data.map((item) => {
          //   item.visible = false;
          //   item.visible1 = false;
          //   item.markTime = item.markTime || 0;
          //   item.examMarkType = item.examMarkType || "";
          // });
          // // markTime

          // this.exammarkList = res.data.data;
          // // console.log(this.exammarkList);
          // this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    schoolExamwork() {
      let data = {
        examPaperId: this.examQuery.paperId,
        schoolId: "-1",
      };
      this.listLoading = true;
      schoolExamwork(data)
        .then((res) => {
          if (res.data.data.length == 0) {
            this.exammarkList = [];
          }
          // 添加两个显示任务量的弹窗
          res.data.data.map((item) => {
            item.visible = false;
            item.visible1 = false;
            item.markTime = item.markTime || 0;
            item.examMarkType = item.examMarkType || "";
          });
          // markTime

          this.exammarkList = res.data.data;
          // console.log(this.exammarkList);
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    checkScale(list) {
      if (list) {
        if (list.scaleType != 2) {
          if (list.carryType == 4 || list.carryType == 5) {
            list.carryType = 3;
          }
        }
      } else {
        if (this.scaleType != 2) {
          if (this.carryType == 4 || this.carryType == 5) {
            this.carryType = 3;
          }
        }
      }
    },
    //判断是否显示选项
    getshow(val, list) {
      let type = true;
      if (list) {
        //
        if (list.scaleType != 2) {
          if (val == 4 || val == 5) {
            type = false;
          }
        }
      } else {
        if (this.scaleType != 2) {
          if (val == 4 || val == 5) {
            type = false;
          }
        }
      }

      return type;
    },
    checkVal(item) {
      this.exammarkList.map((index) => {
        if (
          index.bindId &&
          index.bindQuestionNum.indexOf(item.questionNum) != -1 &&
          item.questionNum != index.questionNum
        ) {
          // if (item.markStep == 1) {
          index.markScore = item.markScore;
          // index.markStep = item.markStep;
          // this.setValueType(index);
          // }
        }
      });
    },
    setValueType(item) {
      if (item.markStep == 1) {
        item.markScore = item.oldmarkScore;
      } else {
        let arr = [];
        let number = "";
        number = parseInt(item.questionScore / item.markScore);

        for (let i = 0; i <= number; i++) {
          arr.push((i * (item.markScore * 10)) / 10);
        }
        // 判断下是否有余值
        if (item.questionScore % item.markScore > 0) {
          arr.push(item.questionScore);
        }
        item.markScore = arr.join(",");
      }
    },
    markStepItemChange(item) {
      if (item.bindId) {
        this.exammarkList.map((index) => {
          if (
            index.bindId &&
            index.bindQuestionNum.indexOf(item.questionNum) != -1 &&
            item.questionNum != index.questionNum
          ) {
            index.markStep = item.markStep;
            this.setValueType(index);
          }
        });
      }

      this.setValueType(item);
    },
    setlistStep() {
      this.exammarkList.map((item) => {
        if (item.markType == 3) return;
        item.markScore = this.stepVal;
      });
    },
    // 批量设置给分约束点
    markStepChange() {
      this.exammarkList.map((item) => {
        if (item.markType == 3) return;
        if (item.markStep == this.markStep && item.markScore != "") {
          return;
        }
        if (this.markStep) {
          item.markStep = this.markStep;
        }

        if (item.markStep == 1) {
          item.markScore = item.oldmarkScore;
        } else {
          let arr = [];
          let number = "";
          if (item.markScore == "") {
            item.markScore = item.oldmarkScore;
          }
          number = parseInt(item.questionScore / item.markScore);
          for (let i = 0; i <= number; i++) {
            arr.push((i * (item.markScore * 10)) / 10);
          }
          // 判断下是否有余值
          if (item.questionScore % item.markScore > 0) {
            arr.push(item.questionScore);
          }

          item.markScore = arr.join(",");
        }
      });
    },
    // 给分方式修改
    markTypeChange() {
      // console.log(val);
      this.exammarkList.map((item) => {
        if (this.markType) {
          item.markType = this.markType;
        }
      });
    },
    submitListCnt() {
      if (this.examQuery.type == 1) {
        // 批量设置规则
        this.submit();
      } else {
        // 批量设置给分方式
        this.submitScore();
      }
    },
    // 提交批量设置给分方式
    submitScore() {
      let markScore = [];
      let str = false;
      this.exammarkList = this.exammarkList.map((item) => {
        if (item.markStep == 1) {
          if (item.markScore > item.questionScore) {
            markScore.push(item.questionNum);
          }
          if (item.markScore === "0" || item.markScore === 0) {
            markScore.push(item.questionNum);
            str = true;
          }
        } else {
          item.markScore = item.markScore.replace("，", ",");
          let markScoreList = item.markScore.split(",");
          let re = /^\d+(?=\.{0,1}\d+$|$)/;
          markScoreList.forEach((index) => {
            if (!re.test(index)) {
              markScore.push(item.questionNum);
            }
            if (index > item.questionScore) {
              markScore.push(item.questionNum);
            }
            if (index.split(".")[1] && index.split(".")[1].length > 2) {
              markScore.push(item.questionNum);
            }
          });
        }
        if (this.minTime) {
          item.useMarkTime = 1;
        } else {
          item.useMarkTime = 0;
        }
        // if (
        //   item.bindQuestionNum &&
        //   item.bindQuestionNum.indexOf(item.questionNum) != 0
        // ) {
        //   this.exammarkList.map((index) => {
        //     if (
        //       index.bindQuestionNum &&
        //       index.questionNum != item.questionNum &&
        //       index.bindQuestionNum.indexOf(item.questionNum) > 0
        //     ) {
        //       item.markStep = index.markStep;
        //       // item.markScore = index.markScore;
        //       item.markTime = index.markTime;
        //       if (item.markStep == 1) {
        //         item.markScore = index.markScore;
        //       } else {
        //         let arr = [];
        //         let number = Number(item.questionScore);
        //         for (let i = 0; i <= number; i++) {
        //           arr.push(i);
        //         }
        //         // 判断下是否有余值

        //         if (item.questionScore % 1 > 0) {
        //           arr.push(item.questionScore);
        //         }

        //         item.markScore = arr.join(",");
        //       }
        //     }
        //   });
        // }
        // console.log(item);
        return item;
      });
      markScore = [...new Set(markScore)];
      if (markScore.length > 0) {
        this.$message({
          message: str
            ? `第${markScore.join("，")}题步长不能为0分`
            : `第${markScore.join("，")}题步长无效`,
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.exammarkRuleScore();
    },
    // 提交批量设置规则
    submit() {
      let errorScore = [];
      let scaleType = [];
      let carryType = [];
      this.exammarkList.map((item) => {
        if (item.ruleScore != 1) {
          if (!item.errorScore && item.errorScore != 0) {
            errorScore.push(item.questionNum);
          }
          if (!item.scaleType) {
            scaleType.push(item.questionNum);
          }
          if (!item.carryType) {
            carryType.push(item.questionNum);
          }
        }
        if (
          item.bindQuestionNum &&
          item.bindQuestionNum.indexOf(item.questionNum) != 0
        ) {
          this.exammarkList.map((index) => {
            if (
              index.bindQuestionNum &&
              index.questionNum != item.questionNum &&
              index.bindQuestionNum.indexOf(item.questionNum) > 0
            ) {
              item.scaleType = index.scaleType;
              item.carryType = index.carryType;
              item.ruleScore = index.ruleScore;
            }
          });
        }
      });
      if (errorScore.length > 0) {
        this.$message({
          message: `第${errorScore.join("，")}题误差分未设置`,
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (scaleType.length > 0) {
        this.$message({
          message: `第${scaleType.join("，")}题小数位数未设置`,
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (carryType.length > 0) {
        this.$message({
          message: `第${carryType.join("，")}题进位方式未设置`,
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.exammarkRuleScore();
    },
    // 设置评分规则-设置给分方式
    exammarkRuleScore() {
      // type 1 评分规则 2 给分方式
      // .filter((item) => item.markType != 3)
      let arr = this.exammarkList;
      let data = {
        examPaperId: this.examQuery.paperId,
        type: this.examQuery.type,
        ruleScore: arr,
      };
      // item
      this.btnLoading = true;
      exammarkRuleScore(data)
        .then(() => {
          this.$message({
            message: "修改成功！",
            type: "success",
            showClose: true,
          });

          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    setQuestionList() {
      if (this.scaleType != 2) {
        if (this.carryType == 4 || this.carryType == 5) {
          this.carryType = 3;
        }
      }
      this.exammarkList.map((item) => {
        // if (item.markType == 3) return;
        if (this.carryType) {
          item.carryType = this.carryType;
        }
        if (this.scaleType) {
          item.scaleType = this.scaleType;
        }

        // if (
        //   item.bindQuestionNum &&
        //   item.bindQuestionNum.indexOf(item.questionNum) != 0
        // ) {
        //   return;
        // }
        if (this.ruleScore) {
          item.ruleScore = this.ruleScore;
        }
        if (this.errorScore !== "") {
          item.errorScore = this.errorScore;
        }
      });
    },
    // 获取按人分配的任务
    exammarkTeacherList() {
      let data = {
        examPaperId: this.examQuery.paperId,
        schoolId: "",
      };
      // if (this.examQuery.markType == 3) {
      //   this.schoolExamwork();
      //   return;
      // }
      exammarkTeacherList(data).then((res) => {
        this.exammarkList = res.data.data;
        this.exammarkList.map((item) => {
          item.markTime = item.markTime || 0;
          if (item.markStep == 1) {
            item.oldmarkScore = item.markScore;
          } else {
            item.oldmarkScore = 1;
          }

          if (item.useMarkTime == 1) {
            this.minTime = true;
          }
        });
      });
    },

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
//
<style lang="scss">
.ExamSetTaskRules {
  .el-radio-box {
    .el-radio {
      :last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.ExamSetTaskRules {
  padding: 24px 0 64px;
  .set-input {
    width: 80px;
    margin-right: 30px;
  }
  .tab-item-title {
    margin-bottom: 8px;
  }
  .mark-score {
    display: block;
    margin: 0 auto;
    width: 332px;
  }

  .tab-box {
    margin: 24px 0 40px;
    background: #ffffff;
    padding: 24px;

    .submit-btn {
      display: block;
      margin: 24px auto 0;
    }
    .tab-item {
      text-align: left;
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
      .el-select,
      .el-input {
        width: 130px;
        margin-left: 12px;
      }
      .score {
        color: #ff0505;
        margin-left: 12px;
      }
    }
    .tips {
      margin: 24px 0;
      border: 1px dashed #ff7575;
      background: #fdf0ee;
      box-shadow: 0px 0px 5px 0px rgba(231, 231, 231, 0.7);
      padding: 12px;
      color: #737677;
      p {
        margin: 12px 0 0 0;
        padding-left: 20px;
      }
      span {
        padding: 0 6px;
        font-size: 12px;
        letter-spacing: 0;
        border-radius: 50%;
        background: #f17e5e;
        color: #ffffff;
      }
    }
  }
  .header-box {
    background-color: #ffffff;
    padding: 24px 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 24px;
      color: #080a09;
      // width: 100%;
      .el-icon-arrow-left {
        cursor: pointer;
      }
    }
  }
}
</style>
